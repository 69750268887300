import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  ButtonGroup,
  Typography,
  IconButton,
  Avatar,
  Breadcrumbs,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ListItemIcon,
  Icon,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { getVars } from "@utils/global";
import Logo from "../../assets/img/PRI_logo.png";

const AppBars = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const AdminNavbar = (props) => {
  const { handleToggle, ...other } = props;
  const token = getVars("Token");
  const navigateMenu = getVars("sideBar");
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  };

  const logOut = () => {
    sessionStorage.clear();
    navigate('/login');
  };

  return (
    <AppBars position="fixed" className="gto-red" {...other}>
      <Toolbar
        sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
      >
        <IconButton
          variant="outlined"
          size="small"
          onClick={(e) => handleToggle()}
          sx={{ marginRight: "40px", marginLeft: "0px" }}
        >
          <Icon className="color-icon">menu</Icon>
        </IconButton>
        <Breadcrumbs
          aria-label="breadcrumb"
          className="text-colors"
          sx={{ flexGrow: 1 }}
        >
          <Typography
            className="text-colors"
            variant="h6"
            component={NavLink}
            to="/"
          >
            {props.title}
          </Typography>
          {navigateMenu.Menu ? (
            <Typography className="text-colors">{navigateMenu.Menu}</Typography>
          ) : (
            ""
          )}
        </Breadcrumbs>
        <ButtonGroup
          //variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <IconButton
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={(e) => {
              setOpen((prevOpen) => !prevOpen);
            }}
            sx={{ color: "white" }}
          >
            <Avatar src={Logo} />
            &nbsp;
            {token.Nombre}
          </IconButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom-start" ? "left top" : "left bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem onClick={handleClose}>
                        <NavLink
                          to={"/mi-perfil"}
                          style={{
                            textDecoration: "none",
                            /* display: "inline-block", */
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <ListItemIcon style={{marginRight: "8px"}}>
                            {/* <Icon size="small">account_circle</Icon> */}
                            <Avatar src={Logo} style={{ width: "35px", height: "35px" }}/>
                          </ListItemIcon>
                          Mi Perfil
                        </NavLink>
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          handleClose(e);
                          logOut(e);
                        }}
                      >
                        <ListItemIcon>
                          <Icon size="small">power_settings_new</Icon>
                        </ListItemIcon>
                        Cerrar sesión
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </ButtonGroup>
      </Toolbar>
    </AppBars>
  );
};

AdminNavbar.propTypes = {
  handleToggle: PropTypes.func,
};

export default AdminNavbar;
