import { useEffect, useState } from "react";
import { useFormik } from "formik";

import {
  Box,
  Card,
  CardContent,
  TextField,
  Typography,
  Stack,
  Button,
  InputAdornment,
  Icon,
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  Alert,
} from "@mui/material";

import BasicSelect from "@components/Selects/BasicSelect";

import sije from "@services/SijeService";
import Swal from "sweetalert2";
import { CapturaInterface } from "@data/interfaces/SijeInterface";
import { CapturaPaseListaSchema } from "@data/schemas/SijeSchema";
import { isEmptyOrInvalidString, isTypePhone } from "@utils/validations";
import moment from "moment";

const CaptureRollCallForm = ({
  idUser,
  title,
  hasListFuncionarios = false,
  reloadFuncionarios = false,
  setReloadFuncionarios,
  handleSave,
}) => {
  const [catCasillas, setCatCasillas] = useState([]);
  const [loadCasillas, setLoadCasillas] = useState(false);
  const [dataFuncionarios, setDataFuncionarios] = useState([]);
  const [checkedsCasillas, setCheckedsCasillas] = useState([]);
  const [loadingFuncionarios, setLoadingFuncionarios] = useState(false);

  const formik = useFormik({
    initialValues: CapturaInterface,
    validationSchema: CapturaPaseListaSchema,
    onSubmit: (values) => {
      const paramsMassive = checkedsCasillas.map(
        ({ idCasilla, idTipoPropietario }) => ({
          idCasilla,
          idTipoPropietario,
          FechaHora: values.hora,
        })
      );

      handleSave(hasListFuncionarios ? paramsMassive : values);
    },
  });

  const handleChangeSection = async (e) => {
    const idSection = e.target.value;
    if (idSection) {
      try {
        setLoadCasillas((prevState) => !prevState);
        const result = await sije.getCasillasBySeccion({
          Seccion: String(idSection),
        });
        const { response, results, message } = await result;
        if (results) {
          const municipio = response.data.map((item) => item.Municipio);
          const valueMunicipio = [...new Set(municipio)];
          const casillas = response.data.map((item) => {
            return {
              label: item.NombreCasilla,
              value: item.idCasilla,
            };
          });
          setCatCasillas(casillas);
          setCheckedsCasillas([]);
          setDataFuncionarios([]);
          formik.setValues({
            ...formik.values,
            seccion: idSection,
            municipio: valueMunicipio[0],
            idUser: idUser,
            //hora: "",
          });
        } else {
          throw new Error(message);
        }
      } catch (error) {
        Swal.fire({
          title: error.message,
          icon: "warning",
        });
      } finally {
        setLoadCasillas((prevState) => !prevState);
      }
    }
  };

  const getFuncionariosCasilla = async (params) => {
    try {
      setLoadingFuncionarios(true);
      const { results, success, response, message } =
        await sije.getFuncionariosCasilla(params);
      if (!results && !success) throw new Error(message);

      if (results && success) {
        setDataFuncionarios(response.data);
        setCheckedsCasillas([]);
      } else {
        setCheckedsCasillas([]);
        setDataFuncionarios([]);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setLoadingFuncionarios(false);
      setReloadFuncionarios(false);
    }
  };

  const handleToggle = (isChecked, item) => {
    const idx = checkedsCasillas
      .map((casilla) => casilla.idTipoPropietario)
      .indexOf(item.idTipoPropietario);

    if (isChecked)
      return setCheckedsCasillas((prevState) => [
        ...prevState,
        {
          idCasilla: formik.values.casilla,
          idTipoPropietario: item.idTipoPropietario,
          //idUsuario: item.idUsuario,
        },
      ]);

    if (!isChecked && idx > -1) {
      return setCheckedsCasillas((prevState) => {
        prevState.splice(idx, 1);
        return JSON.parse(JSON.stringify(prevState));
      });
    }
  };

  const handleToggleAllChecked = (isChecked) => {
    if (isChecked) {
      const all_check = dataFuncionarios
        .filter((item) => item.esPaseLista !== 1)
        .map((item) => ({
          idCasilla: formik.values.casilla,
          idTipoPropietario: item.idTipoPropietario,
        }));

      setCheckedsCasillas(all_check);

      /*  return setCheckedsCasillas(
        dataFuncionarios.map((item) => ({
          idCasilla: formik.values.casilla,
          idTipoPropietario: item.idTipoPropietario,
          //idUsuario: item.idUsuario,
        }))
      ); */
    } else {
      setCheckedsCasillas([]);
    }
  };

  const handleChangeCasilla = (e) => {
    if (hasListFuncionarios) {
      const idCasilla = e.target.value;

      getFuncionariosCasilla({
        idCasilla,
        Seccion: +formik.values.seccion,
      });
    }
  };

  const handleOnkeyDown = (event) => {
    if (event.keyCode === 9) {
      event.preventDefault();
      handleChangeSection(event);
    }
  };

  const handleOnkeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleChangeSection(event);
    }
  };

  useEffect(() => {
    if (reloadFuncionarios) {
      getFuncionariosCasilla({
        idCasilla: formik.values.casilla,
        Seccion: +formik.values.seccion,
      });
    }
  }, [reloadFuncionarios]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      hora: moment().format("HH:mm"),
    });
  }, [idUser]);

  return (
    <Card className="card-primary">
      <CardContent>
        <Typography textAlign={"center"} variant="body2" fontWeight={600}>
          {title}
        </Typography>
        <Typography textAlign={"center"} variant="caption">
          Escribe la sección y posteriormente presiona enter para continuar con
          la captura.
        </Typography>
        <Box component={"form"} marginTop={2}>
          <TextField
            label="Sección"
            name="seccion"
            value={formik.values.seccion}
            onKeyDown={handleOnkeyDown}
            onKeyUp={handleOnkeyPress}
            onChange={(e) => {
              formik.handleChange({
                target: {
                  name: "seccion",
                  value: isTypePhone(e.target.value)
                    ? e.target.value
                    : formik.values.seccion,
                },
              });
              formik.setFieldValue("casilla", "");
            }}
            size="small"
            sx={{ width: "100%", mb: 3 }}
          />
          <TextField
            disabled
            label="Municipio"
            name="municipio"
            value={formik.values.municipio}
            size="small"
            sx={{ width: "100%", mb: 3 }}
          />
          <BasicSelect
            label="Casilla"
            name="casilla"
            options={catCasillas}
            value={formik.values.casilla}
            onChange={(e) => {
              formik.handleChange({
                target: {
                  name: "casilla",
                  value: e.target.value,
                },
              });
              handleChangeCasilla(e);
            }}
            isLoading={loadCasillas}
            error={
              formik.touched.casilla &&
              !isEmptyOrInvalidString(formik.errors.casilla)
            }
            errorMessage={formik.errors.casilla}
            sx={{ width: "100%", mb: 3 }}
          />

          {hasListFuncionarios &&
            (loadingFuncionarios ? (
              <CircularProgress size={20} />
            ) : (
              <>
                {dataFuncionarios.length > 0 && (
                  <Box marginBottom={2}>
                    <Stack direction={"column"} spacing={0.5}>
                      <Typography variant="body2" fontWeight={600}>
                        Selecciona la casilla
                      </Typography>
                      {!dataFuncionarios.some(
                        (item) => item.idTipoPropietario === 0
                      ) && (
                        <Alert severity="info" color="warning">
                          Esta casilla no tiene RG
                        </Alert>
                      )}
                    </Stack>
                    <FormControlLabel
                      control={
                        <Checkbox
                          edge="center"
                          checked={
                            checkedsCasillas.length ===
                              dataFuncionarios.length ||
                            checkedsCasillas.length > 0 ||
                            dataFuncionarios.every((item) => item.esPaseLista)
                          }
                          disabled={dataFuncionarios.every(
                            (item) => item.esPaseLista
                          )}
                          disableRipple
                          onChange={(e) =>
                            handleToggleAllChecked(e.target.checked)
                          }
                          indeterminate={
                            checkedsCasillas.length !==
                              dataFuncionarios.length &&
                            checkedsCasillas.length > 0
                          }
                        />
                      }
                      label=<Typography variant="subtitle2" fontWeight={600}>
                        TODOS
                      </Typography>
                    />
                    <Divider />
                    <List sx={{ width: "100%" }}>
                      {dataFuncionarios.map((item) => {
                        const labelId = `checkbox-list-label-${item.idTipoPropietario}`;

                        return (
                          <ListItem key={item.idTipoPropietario} disablePadding>
                            <ListItemButton
                              role={undefined}
                              onClick={(e) =>
                                handleToggle(e.target.checked, item)
                              }
                              dense
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={
                                    checkedsCasillas.some(
                                      (casilla) =>
                                        casilla.idTipoPropietario ===
                                        item.idTipoPropietario
                                    ) || item.esPaseLista
                                  }
                                  disabled={item.esPaseLista}
                                  disableRipple
                                  inputProps={{ "aria-labelledby": labelId }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                id={labelId}
                                primary={
                                  <Stack direction={"column"} spacing={1}>
                                    <Typography
                                      variant="body2"
                                      fontWeight={600}
                                    >
                                      SECCIÓN {item.Seccion} -{" "}
                                      {item.NombreCasilla}
                                    </Typography>
                                    <Typography variant="caption">
                                      {item.Responsabilidad}
                                      {item.HoraPaseLista &&
                                        `- Hora de pase lista: ${item.HoraPaseLista}`}
                                    </Typography>
                                  </Stack>
                                }
                              />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                )}
              </>
            ))}

          <TextField
            label={"* Hora de Pase de Lista (Formato: 24 hrs, ejemplo: 13:30)"}
            name="hora"
            value={formik.values.hora}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            size="small"
            sx={{ width: "100%", mb: 3 }}
            InputProps={{
              size: "small",
              endAdornment: (
                <InputAdornment position="end">
                  <Icon>schedule</Icon>
                </InputAdornment>
              ),
            }}
            error={
              formik.touched.hora && !isEmptyOrInvalidString(formik.errors.hora)
            }
            helperText={
              formik.touched.hora && formik.errors.hora && formik.errors.hora
            }
          />
          <Stack
            direction={"row"}
            marginTop={4}
            spacing={2}
            justifyContent={"center"}
          >
            {/*   <Button
              variant="outlined"
              size="small"
              color="primaryDark"
              //onClick={handleClearForm}
              disabled={formik.values.seccion.length === 0}
            >
              Limpiar
            </Button> */}
            <Button
              variant="contained"
              onClick={formik.submitForm}
              color="primaryDark"
              disabled={formik.values.seccion.length === 0}
            >
              Guardar
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CaptureRollCallForm;
