import {
	Box,
	Card,
	CardContent,
	Container,
	Divider,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Stack,
	Typography,
	Chip,
	Link,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@src/theme/index";
import logo_sian from "@assets/img/PRI_logo.png";
import android from "@assets/img/android.svg";
import ios from "@assets/img/ios.svg";
import { URL_IMAGES } from "@utils/global";

const DownloadApp = () => {
	return (
		<ThemeProvider theme={theme}>
			<Box
				className="section-image"
				sx={{
					backgroundImage: `url(${require("@assets/img/pri-general.png")})`,
				}}
			>
				<Container component="main" maxWidth="lg">
					<Box
						display={"flex"}
						justifyContent={"center"}
						// alignItems={"center"}
					>
						<Card className="card-login" sx={{ p: 1 }}>
							<Box bgcolor={"#0041a0"} width={"100%"}>
								<Stack
									direction={"row"}
									spacing={21}
									alignItems={"center"}
									sx={{ padding: "40px 20px" }}
								>
									<Box
										component={"img"}
										src={logo_sian}
										width={80}
										height={80}
									/>
									<Box>
										<Typography color={"white"} fontWeight={600} variant="h5">
											Pasos para actualizar la versón de tu aplicación
										</Typography>
										<Typography variant="body2" color={"white"}>
											Sistema de Información de Acción Nacional
										</Typography>
									</Box>
								</Stack>
							</Box>
							<CardContent sx={{ padding: "10px 10px" }}>
								<Stack divider={<Divider />}>
									<Box>
										<Stack direction={"row"}>
											<img alt="android" src={android} height="80px" />
											<Typography
												color="primaryDark"
												fontWeight={600}
												variant="h5"
												sx={{ m: 2 }}
											>
												Android
											</Typography>
										</Stack>
										<List>
											<ListItem>
												<ListItemAvatar>
													<Chip
														label={"1"}
														color="primary"
														variant="outlined"
														size="large"
														sx={{
															fontWeight: "700",
														}}
													/>
												</ListItemAvatar>
												<ListItemText
													primary={
														<Typography
															variant="div"
															fontWeight={700}
															fontSize={15}
														>
															<Link
																target="_blank"
																href="https://play.google.com/store/apps/details?id=host.exp.exponent"
															>
																Descargar Expo Go para Android
															</Link>
														</Typography>
													}
													secondary={
														"Descarga la aplicación de Expo Go en tu celular"
													}
												/>
											</ListItem>
											<ListItem>
												<ListItemAvatar>
													<Chip
														label={"2"}
														color="primary"
														variant="outlined"
														size="large"
														sx={{
															fontWeight: "700",
														}}
													/>
												</ListItemAvatar>

												<ListItemText
													primary={
														<Typography
															variant="div"
															fontWeight={700}
															fontSize={15}
														>
															Escanee el siguiente código QR con un dispositivo
															Android para abrirlo en Expo Go.
														</Typography>
													}
												/>

												<img
													alt="ios"
													src={`${URL_IMAGES}/qr.jpeg`}
													width={"25%"}
												/>
											</ListItem>
										</List>
									</Box>
									<Box>
										<Stack direction={"row"}>
											<img alt="ios" src={ios} height="80px" />
											<Typography
												color="primaryDark"
												fontWeight={600}
												variant="h5"
												sx={{ p: 2, m: 1 }}
											>
												iOS
											</Typography>
										</Stack>
										<List>
											<ListItem>
												<ListItemAvatar>
													<Chip
														label={"1"}
														color="primary"
														variant="outlined"
														size="large"
														sx={{
															fontWeight: "700",
														}}
													/>
												</ListItemAvatar>
												<ListItemText
													primary={
														<Typography
															variant="div"
															fontWeight={700}
															fontSize={15}
														>
															<Link
																target="_blank"
																href="https://apps.apple.com/mx/app/expo-go/id982107779"
															>
																Descargar Expo Go para iOS
															</Link>
														</Typography>
													}
													secondary={
														"Descarga la aplicación de Expo Go en tu celular"
													}
												/>
											</ListItem>
											<ListItem>
												<ListItemAvatar>
													<Chip
														label={"2"}
														color="primary"
														variant="outlined"
														size="large"
														sx={{
															fontWeight: "700",
														}}
													/>
												</ListItemAvatar>

												<ListItemText
													primary={
														<Typography
															variant="div"
															fontWeight={700}
															fontSize={15}
														>
															Contactar al administrador
														</Typography>
													}
												/>
											</ListItem>
										</List>
									</Box>
								</Stack>
							</CardContent>
						</Card>
					</Box>
				</Container>
			</Box>
		</ThemeProvider>
	);
};

export default DownloadApp;
