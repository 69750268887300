import { ApiExec,downloadFile,uploadFiles } from "@utils/ApiExec";

class ElectoralService {

    getList = (params,api) => {
        return ApiExec(params, `electoral/${api}`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };


      getResponsables = (params) => {
        return ApiExec(params, `electoral/get-responsables-electoral`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      getStatsResponsables = (params) => {
        return ApiExec(params, `electoral/get-stats-responsables`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      getListCoordinators = (params) => {
        if(params.export){
          return downloadFile({
            api: "electoral/get-list-coordinators",
            method: "POST",
            data: params,
          })
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
        }else{
        return ApiExec(params, `electoral/get-list-coordinators`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
        }
      };

 
      getStatsRs = (params) => {
        return ApiExec(params, `electoral/get-totales-r`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

    

      getAvanceMetas = (params) => {
        return ApiExec(params, `electoral/avance-metas`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      getAvanceMetasResponsabilidad = (params) => {
        return ApiExec(params, `electoral/avance-metas-responsabilidad`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      getAvanceCasillla = (params) => {
        if(params.export){
          return downloadFile({
            api: "electoral/avance-casillas",
            method: "POST",
            data: params,
          })
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
        }else{
        return ApiExec(params, `electoral/avance-casillas`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
        }
      };

      getDetalleRC = (params) => {
        return ApiExec(params, `electoral/detalle-rc`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      getListRG = (params) => {
        return ApiExec(params, `electoral/get-rgs-routes`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      getListAbogado = (params) => {
        return ApiExec(params, `electoral/get-abogados-routes`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      deleteRGRuta = (params) => {
        return ApiExec(params, `electoral/remove-rg-of-route`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      deleteAbogadoRuta = (params) => {
        return ApiExec(params, `electoral/remove-abogado-of-route`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      asignarRGRuta = (params) => {
        return ApiExec(params, `electoral/assign-rg-to-route`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      asignarAbogadoRuta = (params) => {
        return ApiExec(params, `electoral/assign-abogado-to-route`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

      getListInsaculado = (params) => {
        return ApiExec(params, `electoral/get-insaculados`, "POST")
          .then(function (res) {
            return res;
          })
          .then((res) => {
            return res;
          })
          .catch((e) => {
            return e;
          });
      };

    getListVolunteers = (params) => {
      return ApiExec(params, "electoral/get-volunteers", "POST")
          .then((res) => res)
          .then((res) => res)
          .catch((error) => error);
    };

    // Export rcs

    uploadFile = (params) => {
      return ApiExec(params, "electoral/upload-file", "POST")
        .then((res) => res)
        .then((res) => res)
        .catch((error) => error);
    };

    getListTXT = (params) => {
      return ApiExec(params, "electoral/get-listxt-rcs", "POST")
          .then((res) => res)
          .then((res) => res)
          .catch((error) => error);
    };

    getListTXTRCs = (params) => {
      return ApiExec(params, "electoral/get-list-rcs-txt", "POST")
          .then((res) => res)
          .then((res) => res)
          .catch((error) => error);
    };


    exportTXTRCs  = (params) => {
      return downloadFile({
        api: "electoral/get-rcs-txt",
        method: "POST",
        data: params,
      })
        .then((res) => res)
        .catch((e) => e);
    };

    deleteFile = (params) => {
      return ApiExec(params, "electoral/delete-listxt", "POST")
        .then((res) => res)
        .then((res) => res)
        .catch((error) => error);
    };


    validateFile = (params) => {
      return ApiExec(params, "electoral/validate-listxt", "POST")
        .then((res) => res)
        .then((res) => res)
        .catch((error) => error);
    };

    deleteRCs = (params) => {
      return ApiExec(params, "electoral/delete-rc-txt", "POST")
        .then((res) => res)
        .then((res) => res)
        .catch((error) => error);
    };

    // capacitaciones

    getEventosList = (params) => {
      return ApiExec(params, "eventos/get-events", "POST")
          .then((res) => res)
          .then((res) => res)
          .catch((error) => error);
    };


    // pdf


    setPDFs = (params) => {
      return uploadFiles(params, `electoral/upload-pdf`, "POST")
        .then(function (res) {
          return res;
        })
        .then((res) => {
          return res;
        })
        .catch((e) => {
          return e;
        });
    };
  
    getPDFs  = (params) => {
      return downloadFile({
        api: "electoral/download-pdf",
        method: "POST",
        data: params,
      })
        .then((res) => res)
        .catch((e) => e);
    };

    getPDFsView  = (params) => {
      return ApiExec(params, `electoral/view-pdf`, "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        return res;
      })
      .catch((e) => {
        return e;
      });
    };
  
}

const electoral = new ElectoralService();
export default electoral;
