import { useState, useCallback } from "react";
import {
	GoogleReCaptchaProvider,
	useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import Swal from "sweetalert2";

// Material UI y estilos
import {
	Box,
	Container,
	Typography,
	TextField,
	Icon,
	Card,
	InputAdornment,
	IconButton,
	Button,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@src/theme/index";
import "@pages/auth/LoginStyle.css";

// Componentes
import CustomTable from "@components/Tables/CustomTable";

// Utils
import { RECAPTCHA_SITE_KEY } from "@utils/global";
import { isValidSection } from "@utils/validations";

// Data
import coaliciones from "@data/json/coaliciones_secciones.json";

const CardHeader = () => {
	return (
		<div className="card-header-multimedia">
			<Typography
				sx={{ textAlign: "center", mt: 2 }}
				variant="h5"
				fontWeight={400}
			>
				¡Busca tu Sección para informarte sobre las coaliciones!
			</Typography>
		</div>
	);
};

const CoalicionesPage = () => {
	const [seccion, setSeccion] = useState("");

	const [rows, setRows] = useState([]);
	const { executeRecaptcha } = useGoogleReCaptcha();

	const error = seccion <= 0 && seccion !== "";

	const handleOnKeyPress = (e) => {
		if (e.key === "Enter" && seccion !== "") {
			e.preventDefault();
			onChangeCaptcha(seccion);
		}
	};

	const onChangeCaptcha = useCallback(
		(values) => {
			if (!executeRecaptcha) return;

			executeRecaptcha("login")
				.then((token) => {
					searchSection(values);
				})
				.catch((error) => Swal.fire({ title: error, icon: "warning" }));
		},
		// eslint-disable-next-line
		[executeRecaptcha]
	);

	const getIcon = (values) => {
		const fontSize = 32;

		if (values.startsWith("No") || values.startsWith("no"))
			return <Icon sx={{ color: "red", fontSize: fontSize }}>close</Icon>;
		return <Icon sx={{ color: "green", fontSize: fontSize }}>done</Icon>;
	};

	const searchSection = (values) => {
		const tabla = coaliciones.filter((e) => e.Seccion === parseInt(values));

		if (tabla.length) {
			const format = [
				{
					...tabla[0],
					PPP_AYUNTAMIENTOS: getIcon(tabla[0]?.PPP_AYUNTAMIENTOS),
					PPP_DIPUTADOFEDERAL: getIcon(tabla[0]?.PPP_DIPUTADOFEDERAL),
					PPP_DIPUTADOLOCAL: getIcon(tabla[0]?.PPP_DIPUTADOLOCAL),
					PPP_GUBERNATURA: getIcon(tabla[0]?.PPP_GUBERNATURA),
					PPP_PRESIDENCIAREPUBLICA: getIcon(tabla[0]?.PPP_PRESIDENCIAREPUBLICA),
					PPP_SENADOR: getIcon(tabla[0]?.PPP_SENADOR),

					MPP_AYUNTAMIENTOS: getIcon(tabla[0]?.MPP_AYUNTAMIENTOS),
					MPP_DIPUTADOFEDERAL: getIcon(tabla[0]?.MPP_DIPUTADOFEDERAL),
					MPP_DIPUTADOLOCAL: getIcon(tabla[0]?.MPP_DIPUTADOLOCAL),
					MPP_GUBERNATURA: getIcon(tabla[0]?.MPP_GUBERNATURA),
					MPP_PRESIDENCIAREPUBLICA: getIcon(tabla[0]?.MPP_PRESIDENCIAREPUBLICA),
					MPP_SENADOR: getIcon(tabla[0]?.MPP_SENADOR),
				},
			];

			setRows(format);
		} else setRows([]);
	};

	const columns = [
		{ id: "Seccion", label: "SECCIÓN", sort: false, align: "center" },
		{ id: "Municipio", label: "MUNICIPIO", sort: false },
		{ id: "DistritoFederal", label: "DISTRITO FEDERAL", sort: false },
		{ id: "DistritoLocal", label: "DISTRITO LOCAL", sort: false },

		{
			id: "PPP_AYUNTAMIENTOS",
			label: "AYUNTAMIENTOS",
			sort: false,
			align: "center",
		},
		{
			id: "PPP_DIPUTADOFEDERAL",
			label: "DIPUTADO FEDERAL",
			sort: false,
			align: "center",
		},
		{
			id: "PPP_DIPUTADOLOCAL",
			label: "DIPUTADO LOCAL",
			sort: false,
			align: "center",
		},
		{
			id: "PPP_GUBERNATURA",
			label: "GUBERNATURA",
			sort: false,
			align: "center",
		},
		{
			id: "PPP_PRESIDENCIAREPUBLICA",
			label: "PRESIDENCIA REPUBLICA",
			sort: false,
			align: "center",
		},
		{ id: "PPP_SENADOR", label: "SENADOR", sort: false, align: "center" },

		{
			id: "MPP_AYUNTAMIENTOS",
			label: "AYUNTAMIENTOS",
			sort: false,
			align: "center",
		},
		{
			id: "MPP_DIPUTADOFEDERAL",
			label: "DIPUTADO FEDERAL",
			sort: false,
			align: "center",
		},
		{
			id: "MPP_DIPUTADOLOCAL",
			label: "DIPUTADO LOCAL",
			sort: false,
			align: "center",
		},
		{
			id: "MPP_GUBERNATURA",
			label: "GUBERNATURA",
			sort: false,
			align: "center",
		},
		{
			id: "MPP_PRESIDENCIAREPUBLICA",
			label: "PRESIDENCIA REPUBLICA",
			sort: false,
			align: "center",
		},
		{ id: "MPP_SENADOR", label: "SENADOR", sort: false, align: "center" },
	];

	const subColumns = [
		{
			label: "",
			colspan: 4,
			align: "center",
			sx: { backgroundColor: "#AAA9A9" },
		},
		{
			label: "Coalición PAN - PRI - PRD",
			colspan: 6,
			align: "center",
			sx: { backgroundColor: "#0041a0", color: "#fff" },
		},
		{
			label: "Coalición MORENA - PVEM -PT",
			colspan: 6,
			align: "center",
			sx: { backgroundColor: "#B5261E", color: "#fff" },
		},
	];

	const handleSeccion = (value) => {
		if (!isValidSection(value)) return;
		setSeccion(value);
	};

	return (
		<ThemeProvider theme={theme}>
			<Box
				className="section-image"
				sx={{
					backgroundImage: `url(${require("@assets/img/pri-general.png")})`,
				}}
			>
				<Container component="main" maxWidth={rows.length ? "auto" : "sm"}>
					<Box className="login-container" sx={{ position: "relative" }}>
						<Card className="card-login" sx={{ width: "100%" }}>
							<CardHeader />

							<div className="card-content">
								<TextField
									placeholder="Sección"
									className="input"
									size="small"
									sx={{ mb: 1 }}
									error={error}
									helperText={error ? "Ingresa un sección valida" : ""}
									type="number"
									name="seccion"
									onChange={(e) => handleSeccion(e.target.value)}
									onBlur={(e) => handleSeccion(e.target.value)}
									value={seccion}
									autoFocus
									onKeyPress={handleOnKeyPress}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<IconButton sx={{ cursor: "inherit" }}>
													<Icon>grain_icon</Icon>
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</div>

							<div className="card-action">
								<Button
									className="button-login"
									disabled={seccion === "" || seccion <= 0}
									onClick={() => onChangeCaptcha(seccion)}
								>
									BUSCAR
								</Button>
							</div>

							{rows.length > 0 && (
								<div className="card-content">
									<CustomTable
										rows={rows}
										columns={columns}
										subColumns={subColumns}
										stickyHeader
										maxHeight={250}
										showPagination={false}
										disableHover
										disableCardType
									/>
								</div>
							)}
						</Card>
					</Box>
				</Container>
			</Box>
		</ThemeProvider>
	);
};

const Coaliciones = () => {
	return (
		<>
			<GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
				<CoalicionesPage />
			</GoogleReCaptchaProvider>
		</>
	);
};

export default Coaliciones;
