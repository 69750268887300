import { useEffect, useState } from "react";

import { Box, CircularProgress } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

/* import UserSearchForm from "@components/Sije/CaptureForm/UserSearchForm"; */
import RollCallForm from "@components/Sije/CaptureForm/RollCallForm";
import ListCasillasRollCall from "@components/Sije/CaptureForm/ListCasillasRollCall";
import CaptureRollCallForm from "@components/Sije/CaptureForm/CaptureRollCallForm";
import Switch from "../CaptureForm/Switch";
import CardRepresentative from "@components/Sije/CaptureForm/CardRepresentative";

import Swal from "sweetalert2";
import sije from "@services/SijeService";

const RollCall = (props) => {
  const {
    nameTab,
    setOpenLinearProgress,
    setIsSuccessFormSave,
    setIsLoadingForm,
    isLoadigForm,
    reload,
    dataRC,
    loadingRc,
    userId,
  } = props;

  const [listCasillas, setListCasillas] = useState([]);
  //const [options, setOptions] = useState([]);
  const [user, setUser] = useState({});
  const [loadingListCasillas, setLoadingListCasilla] = useState(false);
  /* const [page, setPage] = useState(0);
  const [totalList, setTotalList] = useState(0); */
  /* const [filterData, setFilterData] = useState({
    Celular: "",
    Nombre: "",
    Paterno: "",
    Materno: "",
  }); */
  const [reloadFuncionarios, setReloadFuncionarios] = useState(false);

  const getCasillasUser = async ({ idUser, reload }) => {
    try {
      setLoadingListCasilla(reload);
      const result = await sije.getCasillasUsuario({ idUsuario: idUser });
      const { response, message, results } = result;
      if (results) {
        setListCasillas(
          response.data.map((item) => ({
            ...item,
            idTipoPropietario: null,
          }))
        );
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setLoadingListCasilla(false);
    }
  };

  const handleSaveForm = async (values) => {
    const { casilla, idUser, hora } = values;
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      setIsSuccessFormSave(false);
      const result = await sije.setPaseLista({
        idCasilla: casilla,
        idUser,
        FechaHora: hora,
      });
      const { results, message } = result;

      if (results) {
        Swal.fire({
          title: message,
          icon: "success",
        });
        //setUser({});
        //setOptions([]);
        /* setFilterData({
          Nombre: "",
          Paterno: "",
          Materno: "",
          Celular: "",
        }); */
        /* setPage(0);
        setTotalList(0); */
        reload();
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
      setIsSuccessFormSave(true);
    }
  };

  const handleMassRollCall = ({ casillas, FechaHora }) => {
    fetchPostMassRollCall({
      params: {
        idUsuario: userId,
        casillas: casillas.map((item) => ({
          idCasilla: item.idCasilla,
          idTipoPropietario: item.idTipoPropietario,
          FechaHora,
        })),
      },
      reloadFuncionarios: false,
    });
  };

  const handleMassRollCallOther = (values) => {
    //return console.log(values);
    fetchPostMassRollCall({
      params: {
        idUsuario: userId,
        casillas: values,
      },
      reloadFuncionarios: true,
    });
  };

  const fetchPostMassRollCall = async ({ params, reloadFuncionarios }) => {
    try {
      setIsLoadingForm(true);
      setOpenLinearProgress(true);
      setIsSuccessFormSave(false);
      const result = await sije.setPaseListaMasivo(params);
      const { results, message } = result;

      if (results) {
        Swal.fire({
          title: message,
          icon: "success",
        });
        getCasillasUser({ idUser: userId, reload: false });
        //setUser({});
        //setOptions([]);
        /*  setFilterData({
          Nombre: "",
          Paterno: "",
          Materno: "",
          Celular: "",
        }); */
        /* setPage(0);
        setTotalList(0); */
        setReloadFuncionarios(reloadFuncionarios);
        reload();
      } else {
        throw new Error(message);
      }
    } catch (error) {
      Swal.fire({
        title: error.message,
        icon: "warning",
      });
    } finally {
      setIsLoadingForm(false);
      setOpenLinearProgress(false);
      setIsSuccessFormSave(true);
    }
  };

  /* const handleClickItem = (id) => {
    const user = options.find((item) => item.value === id);
    //const perfiles = user.idPerfil.map((item) => item.idPerfil);

    getCasillasUser({ idUser: user.value, reload: true });
    setUser(user);
  }; */

  useEffect(() => {
    if (userId !== "") {
      setUser(userId);
      getCasillasUser({ idUser: userId, reload: true });
    }
  }, [userId]);

  return (
    <Grid2 container spacing={2}>
      {/* <Grid2 xs={12} md={6} lg={6}>
        <UserSearchForm
          title="Encuentra y elige al usuario para registrar el pase de lista de la casilla."
          page={page}
          total={totalList}
          setPage={setPage}
          setTotal={setTotalList}
          filterData={filterData}
          options={options}
          setFilterData={setFilterData}
          setOptions={setOptions}
          setUser={setUser}
          handleClickItem={handleClickItem}
        />
      </Grid2> */}
      <Grid2 xs={12} md={6} lg={6}>
        {/* {Object.entries(user).length === 0 && (
          <Box
            className="card-primary"
            component={Card}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"400px"}
          >
            <Stack
              direction={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              alignContent={"center"}
            >
              <Box
                sx={{ backgroundColor: grey[200] }}
                width={"80px"}
                height={"80px"}
                padding={2}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                borderRadius={"50%"}
              >
                <Icon fontSize={"large"} sx={{ color: grey[700] }}>
                  post_add
                </Icon>
              </Box>
              <Typography variant="body2" marginTop={1} fontWeight={600}>
                Busca y selecciona al usuario para poder hacer el registro del
                pase de lista.
              </Typography>
            </Stack>
          </Box>
        )}
 */}
        {!userId ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"600px"}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Switch test={true}>
            <CaptureRollCallForm
              idUser={user}
              title={`${nameTab}`}
              handleSave={handleMassRollCallOther}
              hasListFuncionarios
              reloadFuncionarios={reloadFuncionarios}
              setReloadFuncionarios={setReloadFuncionarios}
              flag={
                listCasillas.length ===
                0 /* && Object.entries(user).length > 0 */
              }
            />
            <RollCallForm
              title={`${nameTab} - RC`}
              itemUser={user}
              setItemUser={setUser}
              //options={options}
              handleSave={handleSaveForm}
              loading={isLoadigForm}
              flag={
                listCasillas.length ===
                1 /* && Object.entries(user).length > 0 */
              }
            />
            <ListCasillasRollCall
              title={`${nameTab} - RGS`}
              itemUser={user}
              data={listCasillas}
              handleSave={handleMassRollCall}
              flag={
                listCasillas.length > 1 /* && Object.entries(user).length > 0 */
              }
              reload={reload}
            />
          </Switch>
        )}
      </Grid2>
      <Grid2 xs={12} md={6} lg={6}>
        <CardRepresentative dataRC={dataRC} loading={loadingRc} />
      </Grid2>
    </Grid2>
  );
};

export default RollCall;
